<template>
  <transition name="slide-fade">
    <div class="slider-container o-filter" v-click-outside="config">
      <div class="o-filter__container">
        <div class="slider-container__range">
          <div class="slider-container__col">
            <span>Desde:</span>
            <div class="slider-container__input">
              <input
                type="text"
                id="min"
                name="min"
                readonly
                :min="PRICE_RANGE_MIN"
                v-model="range[0]"
                :max="PRICE_RANGE_MAX"
                @change="limit($event)"
                class="bb-form bb-form--sm"
              />
              <label for="min">€</label>
            </div>
          </div>
          <div class="slider-container__col">
            <span>Hasta:</span>
            <div class="slider-container__input">
              <input
                @change="limit($event)"
                type="text"
                readonly
                id="max"
                name="max"
                :min="PRICE_RANGE_MIN"
                v-model="range[1]"
                :max="PRICE_RANGE_MAX"
                class="bb-form bb-form--sm"
              />
              <label for="max">€</label>
            </div>
          </div>
        </div>

        <!-- <div class="flex justify-center">
          <div class="flex flex-col mr-4">
            <span>Desde:</span>
            <div class="flex items-center">
              <input
                type="text"
                id="min"
                name="min"
                readonly
                :min="PRICE_RANGE_MIN"
                v-model="range[0]"
                :max="PRICE_RANGE_MAX"
                @change="limit($event)"
              />
              <label for="min" class="ml-2">€</label>
            </div>
          </div>
          <div class="flex flex-col ml-4">
            <span>Hasta:</span>
            <div class="flex items-center">
              <input
                @change="limit($event)"
                type="text"
                readonly
                id="max"
                name="max"
                :min="PRICE_RANGE_MIN"
                v-model="range[1]"
                :max="PRICE_RANGE_MAX"
              />
              <label for="max" class="ml-2">€</label>
            </div>
          </div>
        </div> -->
        <div class="slider-container__slider">
          <vue-slider
            ref="priceFilter"
            class=""
            :min="PRICE_RANGE_MIN"
            :max="PRICE_RANGE_MAX"
            v-model="range"
          ></vue-slider>
        </div>
      </div>

      <!-- <button @click="filterRange" class="o-filter__send">Aplicar filtro</button> -->
      <button @click="filterRange" class="button button--primary button--block">
        Aplicar filtro
      </button>
    </div>
  </transition>
</template>

<script>
// SSR: https://github.com/NightCatSama/vue-slider-component/blob/711dc0a75039d849d7400fc03eb1e306b4393bf9/src/pages/QuickStart.md#server-side-rendering-ssr
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

import { mapGetters } from "vuex";
import CanBeClosedByEscKey from "../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "./Mixins/CloseOnClickOutside";

const PRICE_RANGE_MIN = 0;
const PRICE_RANGE_MAX = 3000;

export default {
  name: "PriceFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside],
  components: {
    VueSlider,
  },
  data() {
    return {
      range: [PRICE_RANGE_MIN, PRICE_RANGE_MAX],
    };
  },
  computed: {
    ...mapGetters({
      productFilters: "productFilters",
    }),
  },
  methods: {
    limit(event) {
      if (
        event.srcElement.value > PRICE_RANGE_MAX ||
        event.srcElement.value < PRICE_RANGE_MIN
      ) {
        event.srcElement.value = 0;
      }
    },
    filterRange() {
      const range = this.$refs.priceFilter.getValue();
      let newFilters = [...this.productFilters];

      const found = newFilters.find((filter) => filter.name === "price");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }

      newFilters.push({
        name: "price",
        values: {
          minPrice: range[0],
          maxPrice: range[1],
        },
      });
      this.$setProductFilters(newFilters);
      this.close();
    },
  },
  created() {
    this.PRICE_RANGE_MIN = PRICE_RANGE_MIN;
    this.PRICE_RANGE_MAX = PRICE_RANGE_MAX;
  },
  mounted() {
    const priceFilter = this.productFilters.find(
      (filter) => filter.name === "price"
    );
    if (priceFilter) {
      this.$set(this.range, 0, priceFilter.values.minPrice);
      this.$set(this.range, 1, priceFilter.values.maxPrice);
    }
  },
};
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
