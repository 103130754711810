<template>
  <section class="g-catalogo-filters">
    <catalogue-mobile-filters />
    <catalogue-desktop-filters />
    <catalogue-sort />
  </section>
</template>

<script>
import CatalogueMobileFilters from "./CatalogueMobileFilters";
import CatalogueDesktopFilters from "./CatalogueDesktopFilters";
import CatalogueSort from "./CatalogueSort";

export default {
  name: "CatalogueFilters",
  components: {
    CatalogueMobileFilters,
    CatalogueDesktopFilters,
    CatalogueSort,
  }
}
</script>