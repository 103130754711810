<template>
  <div id="catalogue_filters_for_dektop" class="g-catalogo-filters__desktop relative">
    <div class="d-filters">

      <p>Filtrar:</p>

      <div @click.self="toggleShowPriceFilter" class="price-range d-filters__item">
        Precio
        <svg
            @click.self="toggleShowPriceFilter"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <price-filter v-if="showFilter === 'precio'" @close="showFilter=null"/>
      </div>

      <!--<div @click.self="toggleShowBrandFilter" class="price-range d-filters__item">
        Marca
        <svg
            @click.self="toggleShowBrandFilter"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <brand-filter v-if="showFilter === 'brand'" @close="showFilter=null"></brand-filter>
      </div>-->

      <div @click.self="toggleShowRatingFilter" class="price-range d-filters__item">
        Valoración
        <svg
            @click.self="toggleShowRatingFilter"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <rating-filter v-if="showFilter === 'rating'" @close="showFilter=null"></rating-filter>
      </div>

      <div @click.self="toggleShowRetailerFilter" class="price-range d-filters__item">
        Vendedor
        <svg
            @click.self="toggleShowRetailerFilter"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <retailer-filter v-if="showFilter === 'retailer'" @close="showFilter=null"></retailer-filter>
      </div>

      <div v-if="showTagsFilters">
        <div v-for="tag in tags" @click.self="toggleShowTagFilter(tag)" class="price-range d-filters__item">
          {{ tag.name }}
          <svg
              @click.self="toggleShowTagFilter(tag)"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-down"
              width="48"
              height="48"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#000"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
          <tag-filter :tag="tag" v-if="showFilter === 'tag'" @close="showFilter=null"></tag-filter>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import PriceFilter from '../../../components/PriceFilter'
import BrandFilter from '../../../components/BrandFilter'
import RatingFilter from '../../../components/RatingFilter'
import TagFilter from '../../../components/TagFilter'
import RetailerFilter from '../../../components/RetailerFilter'
import {mapGetters} from "vuex"

export default {
  name:'CatalogueDesktopFilters',
  components: {
    'price-filter': PriceFilter,
    'brand-filter': BrandFilter,
    'rating-filter': RatingFilter,
    'tag-filter': TagFilter,
    'retailer-filter': RetailerFilter,
  },
  data() {
    return {
      showFilter: null,
    }
  },
  computed: {
    ...mapGetters({
      tags: 'tags',
      waitingFilters: 'waitingFilters'
    }),
    showTagsFilters() {
      return this.tags.length > 0 && this.$route.name === 'subcategory' && !this.waitingFilters
    }
  },
  methods: {
    toggleShowFilter(name) {
      if (this.showFilter === name) {
        this.showFilter = null
        return
      }
      this.showFilter = name
    },
    toggleShowPriceFilter() {
      return this.toggleShowFilter('precio')
    },
    toggleShowBrandFilter() {
      return this.toggleShowFilter('brand')
    },
    toggleShowRatingFilter() {
      return this.toggleShowFilter('rating')
    },
    toggleShowRetailerFilter() {
      return this.toggleShowFilter('retailer')
    },
    toggleShowTagFilter() {
      return this.toggleShowFilter('tag')
    },
  },
}
</script>
