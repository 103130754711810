<template>
  <div class="g-catalogo-filters__sort">
    <div class="g-catalogo-filters__sort__label">Ordenar:</div>
    <div data-delay="0" class="g-catalogo-filters__sort__content">
      <div @click.self="show = !show" class="d-filters__sort">
        {{ selectedSort }}
        <svg
          @click.self="show = !show"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#000"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <transition v-if="show" name="slide-fade">
          <div
            class="slider-container slider-container--min o-filter o-filter--padding-0"
            v-click-outside="config"
          >
            <div class="o-filter__container">
              <ul class="o-filter__dropdown">
                <li
                  v-for="(sort, sortKey) in SORT_BY"
                  @click="sortBy(sortKey)"
                  :key="sort"
                  class="o-filter__dropdown__item"
                >
                  {{ sort }}
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import CloseOnClickOutside from "../../../components/Mixins/CloseOnClickOutside";
import CanBeClosedByEscKey from "../../../Mixins/CanBeClosedByEscKey";

const SORT_BY = {
  relevance: "Relevancia",
  price: "Precio",
  rate: "Valoración",
};

export default {
  name: "CatalogueSort",
  mixins: [CloseOnClickOutside, CanBeClosedByEscKey],
  data() {
    return {
      selectedSort: SORT_BY["relevance"],
      show: false,
      orderBy: "relevance",
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    sortBy(sort) {
      this.$store.commit("setOrderBy", sort);
      this.$emit("change");
      this.selectedSort = SORT_BY[sort];
      this.show = !this.show;
      this.$router
        .push({ query: { ...this.$route.query, sort: sort } })
        .catch((err) => {});
    },
  },
  created() {
    this.SORT_BY = SORT_BY;
  },
  mounted() {
    if ("sort" in this.$route.query) {
      this.selectedSort = SORT_BY[this.$route.query.sort];
    }
  },
};
</script>
