<template>
  <transition name="slide-fade">
    <div class="slider-container o-filter" v-click-outside="config">
      <div class="o-filter__container">
        <ul class="o-filter__list" role="list">
          <li v-for="retailer in retailers" :key="retailer.id">
            <label class="w-checkbox">
              <input
                type="checkbox"
                :value="retailer"
                v-model="selectedRetailers"
                class="w-checkbox-input"
                :id="'retailer_' + retailer.id"
              />
              <span class="w-form-label">{{ retailer }}</span>
            </label>
          </li>
        </ul>
      </div>
      <input
        @click="applyFilter"
        class="button button--primary button--block"
        type="submit"
        value="Aplicar filtro"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import CanBeClosedByEscKey from "../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "./Mixins/CloseOnClickOutside";

export default {
  name: "RetailerFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside],
  data() {
    return {
      selectedRetailers: [],
    };
  },
  computed: {
    ...mapGetters({
      productFilters: "productFilters",
      retailers: "retailers",
    }),
  },
  methods: {
    applyFilter() {
      let newFilters = [...this.productFilters];

      const found = newFilters.find((filter) => filter.name === "retailer");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }

      newFilters.push({
        name: "retailer",
        values: {
          retailers: this.selectedRetailers,
        },
      });
      this.$setProductFilters(newFilters);
      this.close();
    },
  },
  mounted() {
    const retailerFilter = this.productFilters.find(
      (filter) => filter.name === "retailer"
    );
    if (retailerFilter) {
      this.selectedRetailers = retailerFilter.values.retailers;
    }
  },
};
</script>
