<template>
  <section class="filtres-aplicats">

    <div v-if="showPriceFilter" class="filtres-aplicats__item">
      <div class="filtres-aplicats__label">Precio:</div>
      <div class="filtres-aplicats__button">
        <div class="filtres-aplicats__" v-text="currentPriceFilterRange"/>
        <img @click="removeFilter('price')"
              src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
              loading="lazy" alt="" class="filtres-aplicats__ico">
      </div>
    </div>

    <div v-if="showBrandFilter" class="filtres-aplicats__item">
      <div class="filtres-aplicats__label">Marca:</div>
      <div class="filtres-aplicats__button" v-for="brand in filteredBrands">
        <div class="filtres-aplicats__name">{{ brand.name }}</div>
        <img @click="removeBrandFilter(brand)"
              src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
              loading="lazy" alt="" class="filtres-aplicats__ico">
      </div>
    </div>

    <div v-if="showRatingFilter" class="filtres-aplicats__item">
      <div class="filtres-aplicats__label">Valoración:</div>
      <div class="filtres-aplicats__button">
        <div class="text-block-84" v-text="currentRatingFilterRange"/>
        <img @click="removeFilter('rating')"
              src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
              loading="lazy" alt="" class="filtres-aplicats__ico">
      </div>
    </div>

    <div v-if="showRetailerFilter" class="filtres-aplicats__item">
      <div class="filtres-aplicats__label">Vendedor:</div>
      <div class="filtres-aplicats__button" v-for="retailer in filteredRetailers">
        <div class="filtres-aplicats__name">{{retailer}}</div>
        <img @click="removeRetailerFilter(retailer)"
             src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
             loading="lazy" alt="" class="filtres-aplicats__ico">
      </div>
    </div>

    <div v-if="showTagFilter" class="filtres-aplicats__item">
      <div class="filtres-aplicats__label">Tag:</div>
      <div class="filtres-aplicats__button" v-for="tag in filteredTags">
        <div class="filtres-aplicats__name">{{tag.name}}</div>
        <img @click="removeTagFilter(tag)"
             src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
             loading="lazy" alt="" class="filtres-aplicats__ico">
      </div>
    </div>

    <div v-if="showRemoveAllFilters" class="filtres-aplicats__item">
      <div v-if="showRemoveAllFilters" @click="removeAllFilters" class="filtres-aplicats__button filtres-aplicats__button--clear">
        <div class="filtres-aplicats__name">Borrar filtros</div>
        <img src="https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5e3950eb2e417c24eb812c4f_close.png"
              loading="lazy" alt="Borrar filtros" class="filtres-aplicats__ico">
      </div>
    </div>

  </section>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: 'AppliedFilters',
  computed: {
    ...mapGetters({
      productFilters: 'productFilters',
    }),
    filteredBrands() {
      if (this.productFilters && Array.isArray(this.productFilters)) {
        const brandFilter = this.productFilters.find(filter => filter.name === 'brand')
        if (brandFilter) {
          const values = brandFilter.values
          if (values) return values.brands
        }
      }
      return []
    },
    filteredRetailers() {
      if (this.productFilters && Array.isArray(this.productFilters)) {
        const retailerFilter = this.productFilters.find(filter => filter.name === 'retailer')
        if (retailerFilter) {
          const values = retailerFilter.values
          if (values) return values.retailers
        }
      }
      return []
    },
    filteredTags() {
      if (this.productFilters && Array.isArray(this.productFilters)) {
        const tagFilter = this.productFilters.find(filter => filter.name === 'tag')
        if (tagFilter) {
          const values = tagFilter.values
          if (values) return values.tags
        }
      }
      return []
    },
    currentRatingFilterRange() {
      if (this.productFilters && Array.isArray(this.productFilters)) {
        const ratingFilter = this.productFilters.find(filter => filter.name === 'rating')
        if (ratingFilter) {
          const values = ratingFilter.values
          if (values) {
            return 'Entre ' + values.minRating + ' y ' + values.maxRating
          }
        }
      }
      return 'Entre 0 y 100€'
    },
    currentPriceFilterRange() {
      if (this.productFilters && Array.isArray(this.productFilters)) {
        const priceFilter = this.productFilters.find(filter => filter.name === 'price')
        if (priceFilter) {
          const values = priceFilter.values
          if (values) {
            return 'Entre ' + values.minPrice + ' y ' + values.maxPrice + ' €'
          }
        }
      }
      return 'Entre 0 y 100€'
    },
    showRemoveAllFilters() {
      return Array(this.productFilters) && this.productFilters.length > 0
    },
    showPriceFilter() {
      return this.showFilter('price')
    },
    showBrandFilter() {
      return this.showFilter('brand')
    },
    showRatingFilter() {
      return this.showFilter('rating')
    },
    showRetailerFilter() {
      return this.showFilter('retailer')
    },
    showTagFilter() {
      return this.showFilter('tag')
    },
  },
  methods: {
    showFilter(name) {
      return !!this.productFilters.find(filter => filter.name === name)
    },
    removeBrandFilter(brand) {
      let newFilters = [...this.productFilters]
      let newBrands = []
      const found = newFilters.find(filter => filter.name === 'brand');
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
        newBrands = found.values.brands.filter(b => b !== brand)
        if (newBrands.length > 0) {
          newFilters.push({
            name: 'brand',
            values: {
              brands: newBrands
            }
          })
        }
      }
      this.$setProductFilters(newFilters)
    },
    removeRetailerFilter(retailer) {
      let newFilters = [...this.productFilters]
      let newRetailers = []
      const found = newFilters.find(filter => filter.name === 'retailer');
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
        newRetailers = found.values.retailers.filter(b => b !== retailer)
        if (newRetailers.length > 0) {
          newFilters.push({
            name: 'retailer',
            values: {
              retailers: newRetailers
            }
          })
        }
      }
      this.$setProductFilters(newFilters)
    },
    removeTagFilter(tag) {
      let newFilters = [...this.productFilters]
      let newTags = []
      const found = newFilters.find(filter => filter.name === 'tag');
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
        newTags = found.values.tags.filter(b => b !== tag)
        if (newTags.length > 0) {
          newFilters.push({
            name: 'tag',
            values: {
              tags: newTags
            }
          })
        }
      }
      this.$setProductFilters(newFilters)
    },
    removeFilter(name) {
      let newFilters = [...this.productFilters]
      const found = newFilters.find(filter => filter.name === name);
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      this.$setProductFilters(newFilters)
    },
    removeAllFilters() {
      this.$setProductFilters([])
    }
  },
  mounted () {
    const filtersQS = this.$route.query.filters
    if (typeof filtersQS === 'string' || filtersQS instanceof String) {
      const filters = JSON.parse(filtersQS)
      this.$setProductFilters(filters)
    } else {
      this.$setProductFilters([])
    }
  }
}
</script>
