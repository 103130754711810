<template>
  <transition name="slide-fade">
    <div class="slider-container o-filter" v-click-outside="config">
        <div class="o-filter__container">
          <ul class="o-filter__list" role="list">
            <li v-for="tag in tag.tag">
              <label class="w-checkbox">
                <input
                    class="w-checkbox-input"
                    data-name="Checkbox 2"
                    :value="tag"
                    v-model="selectedTags"
                    type="checkbox">
                <span class="w-form-label">{{ tag.name }}</span>
              </label>
            </li>
          </ul>
        </div>
        <input @click="applyFilter" class="o-filter__send filtresdesktop" data-wait="Un segundo..." type="submit"
              value="Aplicar filtro">
    </div>
  </transition>
</template>

<script>

import {mapGetters} from "vuex"
import CanBeClosedByEscKey from "../Mixins/CanBeClosedByEscKey"
import CloseOnClickOutside from "./Mixins/CloseOnClickOutside"

export default {
  name: 'TagFilter',
  mixins: [CanBeClosedByEscKey,CloseOnClickOutside],
  data() {
    return {
      selectedTags: []
    }
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      productFilters: 'productFilters',
    }),
  },
  methods: {
    applyFilter() {
      let newFilters = [...this.productFilters]

      const found = newFilters.find(filter => filter.name === 'tag');
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }

      newFilters.push({
        name: 'tag',
        values: {
          tags: this.selectedTags
        }
      })
      this.$setProductFilters(newFilters)
      this.close()
    },
  },
  mounted() {
    const tagFilter = this.productFilters.find(filter => filter.name === 'tag');
    if (tagFilter) {
      this.selectedTags = tagFilter.values.tags
    }
  }
}
</script>
