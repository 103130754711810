<template>
  <transition name="slide-fade">
    <div class="slider-container o-filter" v-click-outside="config">
      <div class="o-filter__container">
        <ul role="list" class="o-filter__list">
          <li v-for="brand in brands">
            <label class="w-checkbox">
              <input
                :value="brand"
                v-model="selectedBrands"
                type="checkbox"
                :id="'checkbox-' + brand.name"
                :name="'checkbox-' + brand.name"
                class="w-checkbox-input"
              />
              <span class="w-form-label">{{ brand.name }}</span>
            </label>
          </li>
        </ul>
      </div>

      <button @click="applyFilter" class="button button--primary button--block">
        Aplicar filtro
      </button>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import CanBeClosedByEscKey from "../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "./Mixins/CloseOnClickOutside";

export default {
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside],
  name: "BrandFilter",
  data() {
    return {
      selectedBrands: [],
    };
  },
  computed: {
    ...mapGetters({
      productFilters: "productFilters",
      brands: "brands",
    }),
  },
  methods: {
    applyFilter() {
      let newFilters = [...this.productFilters];

      const found = newFilters.find((filter) => filter.name === "brand");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }
      newFilters.push({
        name: "brand",
        values: {
          brands: this.selectedBrands,
        },
      });
      this.$setProductFilters(newFilters);
      this.close();
    },
  },
  mounted() {
    const brandFilter = this.productFilters.find(
      (filter) => filter.name === "brand"
    );
    if (brandFilter) {
      this.selectedBrands = brandFilter.values.brands;
    }
  },
};
</script>
