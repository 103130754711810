<template>
  <transition name="slide-fade">
    <div class="slider-container o-filter" v-click-outside="config">
      <div class="o-filter__container">
        <div class="slider-container__range">
          <div class="slider-container__col">
            <span>Desde:</span>
            <div class="slider-container__input">
              <input
                type="text"
                readonly
                id="min"
                name="min"
                :min="RATING_RANGE_MIN"
                v-model="range[0]"
                :max="RATING_RANGE_MAX"
                @change="limit($event)"
                class="bb-form bb-form--sm"
              />
              <label for="min">
                <img
                  src="../assets/images/star_yellow.png"
                  loading="lazy"
                  alt="Estrella"
                />
              </label>
            </div>
          </div>
          <div class="slider-container__col">
            <span>Hasta:</span>
            <div class="slider-container__input">
              <input
                @change="limit($event)"
                type="text"
                readonly
                id="max"
                name="max"
                :min="RATING_RANGE_MIN"
                v-model="range[1]"
                :max="RATING_RANGE_MAX"
                class="bb-form bb-form--sm"
              />
              <label for="max">
                <img
                  src="../assets/images/star_yellow.png"
                  loading="lazy"
                  alt="Estrella"
                />
              </label>
            </div>
          </div>
        </div>

        <div class="slider-container__slider">
          <vue-slider
            ref="ratingFilter"
            class=""
            :min="RATING_RANGE_MIN"
            :max="RATING_RANGE_MAX"
            v-model="range"
          ></vue-slider>
        </div>
      </div>

      <button @click="filterRange" class="button button--primary button--block">
        Aplicar filtro
      </button>
    </div>
  </transition>
</template>

<script>
// SSR: https://github.com/NightCatSama/vue-slider-component/blob/711dc0a75039d849d7400fc03eb1e306b4393bf9/src/pages/QuickStart.md#server-side-rendering-ssr
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";
import { mapGetters } from "vuex";
import CanBeClosedByEscKey from "../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "./Mixins/CloseOnClickOutside";

const RATING_RANGE_MIN = 0;
const RATING_RANGE_MAX = 5;

export default {
  name: "RatingFilter",
  mixins: [CanBeClosedByEscKey, CloseOnClickOutside],
  components: {
    VueSlider,
  },
  data() {
    return {
      range: [RATING_RANGE_MIN, RATING_RANGE_MAX],
    };
  },
  computed: {
    ...mapGetters({
      productFilters: "productFilters",
    }),
  },
  methods: {
    limit(event) {
      if (
        event.srcElement.value > RATING_RANGE_MAX ||
        event.srcElement.value < RATING_RANGE_MIN
      ) {
        event.srcElement.value = 0;
      }
    },
    filterRange() {
      const range = this.$refs.ratingFilter.getValue();
      let newFilters = [...this.productFilters];

      const found = newFilters.find((filter) => filter.name === "rating");
      if (found) {
        newFilters.splice(newFilters.indexOf(found), 1);
      }

      newFilters.push({
        name: "rating",
        values: {
          minRating: range[0],
          maxRating: range[1],
        },
      });
      this.$setProductFilters(newFilters);
      this.close();
    },
  },
  created() {
    this.RATING_RANGE_MIN = RATING_RANGE_MIN;
    this.RATING_RANGE_MAX = RATING_RANGE_MAX;
  },
  mounted() {
    const ratingFilter = this.productFilters.find(
      (filter) => filter.name === "rating"
    );
    if (ratingFilter) {
      this.$set(this.range, 0, ratingFilter.values.minRating);
      this.$set(this.range, 1, ratingFilter.values.maxRating);
    }
  },
};
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* .slider-container {

  width: 235px;
  position: absolute;
  background: #fff;
  padding: 17px 3px 3px 3px;
  right: -130px;
  top: 40px;
}
.slider-container input {
  border: 1px solid #afafaf;
  border-radius: 4px;
  width: 55px;
  height: 40px;
  color: #868686;
  padding: 0 7px;
}
.slider-container button {
  background-color: #162633;
  border: 2px solid #162633;
  color: #fff;
  transition: all 0.3s ease;
  padding: 12px;
  width: 100%;
}
.slider-container button:hover {
  background-color: #fff;
  color: #162633;
} */
</style>
